<template>
  <Layout>
    <template v-slot:input>
      <div class="option flex justify-between items-center text-left">
        <div class="option-label text-gray-400 text-xs select-none w-3/4">
          <span
            class="cursor-pointer hover:underline"
            @click.prevent="edit = !edit"
          >{{ value }} <fa-icon icon="pencil" /></span>
        </div>
        <div class="w-1/4 text-right">
          <div class="block">
            <a
              href="#"
              class="text-sm leading-tight text-blue-500 hover:underline hover:text-blue-400"
              @click.prevent="view"
            >View</a>
          </div>
        </div>
      </div>

      <MappingInputTable
        v-if="edit"
        :available="available"
        :selected="selected"
        @changed="onSelectedChange"
      />

      </div>
    </template>
  </Layout>
</template>
<script>
const MappingInputTable = () => import('./MappingInputTable')
const Layout = () => import('./Layout')

export default {
  name: 'MappingInput',

  components: {
    Layout,
    MappingInputTable
  },

  props: ['row', 'rowkey', 'keypath'],

  data () {
    return {
      edit: false,
      selectedVals: []
    }
  },

  computed: {

    id () {
      return this.row.id
    },

    isDisabled () {
      if (this.row.disabled) {
        return this.row.disabled
      }
      return false
    },

    value () {
      let ret = ''
      this.selected.forEach(row => {
        if (ret !== '') ret = ret + ', '
        ret = ret + row.name + ':' + row.uv
      })

      return ret
    },

    available () {
      return this.row.available
    },

    selected () {
      return this.row.selected
    }

  },

  mounted () {
    this.selectedVals = this.selected
  },

  methods: {

    onSelectedChange (selected) {
      this.selectedVals = selected

      this.$bus.$emit('mappings:change', {
        id: this.id,
        selected: selected
      })
    },

    view () {
      let key = 'view.toggleMappings'
      // let value = this.id

      console.log('VALUE Is : ', this.selectedVals)
      this.$bus.$emit('viewer:action', {
        key: key,
        value: this.selectedVals,
        extra: { 'id': this.id }
      })
    }
  }
}

</script>
